<template>
  <div class="mage-tree-details-page">
    <stand-out-block class="settings-item light">
      <h6 class="heading-6">Tree Name</h6>
      <input
        v-model="newName"
        :disabled="!allowEdit"
        ref="name"
        class="full-width input"
        placeholder="Placeholder"
        @keyup.enter="saveTreeName"
      />
      <div class="action-buttons" v-if="allowEdit">
        <mcr-button class="save-button" :disabled="nameButtonDisabled" :loading="saveNameLoading" @click="saveTreeName"
          >Save
        </mcr-button>
      </div>
    </stand-out-block>

    <stand-out-block class="settings-item light">
      <h6 class="heading-6">Root Person</h6>
      <family-tree-people-multiselect-container
        ref="people-select"
        class="bordered"
        :family-tree-id="treeId"
        :value="selectedHomePerson"
        :multiple="false"
        :allow-clear="false"
        :disabled="!allowEdit"
        @select="selectHomePerson"
      ></family-tree-people-multiselect-container>
      <div class="action-buttons" v-if="allowEdit">
        <mcr-button
          class="save-button"
          :disabled="homePersonButtonDisabled"
          :loading="saveHomePersonLoading"
          @click="saveHomePerson"
          >Save
        </mcr-button>
      </div>
    </stand-out-block>
    <div class="reset-expand" v-if="userIsStaffState"><a @click="resetExpand">Reset expand</a></div>
    <div class="delete-link-container" v-if="allowEdit">
      <a @click="openDeleteModal" class="delete-link">
        <delete-icon />
        Delete this tree</a
      >
    </div>
    <delete-tree-modal
      v-if="familyTreeDetailsState.name"
      :tree-id="familyTreeDetailsState.object_id"
      :tree-name="familyTreeDetailsState.name"
      :persons-count="familyTreeDetailsState.persons_count"
      :assets-count="familyTreeDetailsState.assets_count"
    ></delete-tree-modal>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import DeleteIcon from 'vue-material-design-icons/Delete';
import {mapGetters} from 'vuex';

import FamilyTreePeopleMultiselectContainer from '@/components/common/inputs/FamilyTreePeopleMultiselectContainer';

import DeleteTreeModal from './DeleteTreeModal';

export default {
  data() {
    const familyTree = this.$store.getters.familyTreeDetailsState;
    return {
      newName: familyTree.name,
      saveNameLoading: false,
      selectedHomePerson: {
        object_id: familyTree.home_person.id,
        full_name: familyTree.home_person.full_name || 'Unknown name',
      },
      saveHomePersonLoading: false,
    };
  },
  computed: {
    ...mapGetters(['familyTreeDetailsState', 'familyTreeDetailsLoadingState', 'userIsStaffState']),
    nameButtonDisabled() {
      return this.familyTreeDetailsState.name === this.newName;
    },
    homePersonButtonDisabled() {
      const homePerson = this.familyTreeDetailsState.home_person || {};
      return homePerson.id === this.selectedHomePerson.object_id;
    },
    treeId() {
      return this.$route.params.id;
    },
    allowEdit() {
      return this.familyTreeDetailsState.is_owner;
    },
  },
  methods: {
    saveTreeName() {
      this.$refs.name.blur();
      const name = this.newName;
      if (this.nameButtonDisabled) {
        return;
      }
      this.saveNameLoading = true;
      this.$store.commit('mutateFamilyTreeDetailsState', {name});
      this.$store
        .dispatch('updateFamilyTreeAction', {id: this.treeId, name: name})
        .then(() => {
          this.$toasted.success('Tree name updated');
        })
        .catch(() => {
          this.$toasted.error('Error updating tree name');
        })
        .finally(() => {
          this.saveNameLoading = false;
        });
    },
    selectHomePerson(value) {
      this.selectedHomePerson = value;
    },
    saveHomePerson() {
      if (this.homePersonButtonDisabled) {
        return;
      }
      this.saveHomePersonLoading = true;
      this.$store
        .dispatch('updateFamilyTreeAction', {id: this.treeId, home_person_id: this.selectedHomePerson.object_id})
        .then(response => {
          this.$toasted.success('Root person updated');
          this.$store.commit('mutateFamilyTreeDetailsState', {home_person: response.home_person});
        })
        .catch(() => {
          this.$toasted.error('Error updating root person');
        })
        .finally(() => {
          this.saveHomePersonLoading = false;
        });
    },
    openDeleteModal() {
      this.$modal.show('delete-tree-modal');
    },
    resetExpand() {
      this.$store
        .dispatch('treeSetExpandControlAction', {
          treeId: this.treeId,
          data_list: [{action: 'reset'}],
          start_person_id: '*',
        })
        .then(() => {
          this.$store.dispatch('cleanupTreeStateAction');
          this.$toasted.success('Expand is reset.');
        });
    },
  },
  components: {
    DeleteTreeModal,
    StandOutBlock,
    McrButton,
    FamilyTreePeopleMultiselectContainer,
    DeleteIcon,
  },
  name: 'ManageTreeDetailsPage',
};
</script>

<style lang="scss" scoped>
.reset-expand {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
  margin-right: 20px;
}
.delete-link-container {
  display: flex;
  justify-content: flex-end;

  .delete-link {
    flex-grow: 0;
    display: flex;
    align-items: center;
    margin-top: 25px;
    margin-right: 20px;

    .delete-icon {
      margin-right: 5px;
    }
  }
}
</style>
